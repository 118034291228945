import { TextArea } from 'src/components/lib/textArea';
import { TextField } from 'src/components/lib/textField';
import styled from 'styled-components';
import tw from 'twin.macro';

export const StyledForm = styled.form`
  ${tw`
    border-width[1px]
    border-radius[25px]
    mt-8
    p-4
    lg:p-6
  `}
`;

export const StyledTextField = styled(TextField)`
  ${tw`
    m-3
    lg:m-4
  `}
`;

export const StyledTextArea = styled(TextArea)`
  ${tw`
    m-3
    lg:m-4
  `}
`;
