import { graphql } from 'gatsby';
import * as React from 'react';
import { Layout } from 'src/components/layout/layout';
import { SubmitReport } from 'src/components/submit-report';

function BountyDetail(props) {
  const { contentfulBounties: bounty } = props.data;
  return (
    <Layout>
      <SubmitReport bounty={bounty} />
    </Layout>
  );
}

export default BountyDetail;

export const query = graphql`
  query ($id: String!) {
    contentfulBounties(id: { eq: $id }) {
      id
      title
      author
      access {
        raw
      }
      description {
        raw
      }
      motivation {
        raw
      }
    }
  }
`;
