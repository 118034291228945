import React from 'react';
import { LeftArrow } from '../../icons';
import 'twin.macro';
import { navigate } from 'gatsby';

export function BackLink() {
  return (
    <div className="flex mb-5 align-items[center] cursor-pointer" onClick={() => navigate(-1)}>
      <LeftArrow />
      <div className="pl-2 font-size[18px]">Back</div>
    </div>
  );
}
