import React, { useState } from 'react';
import { StyledForm, StyledLabel, StyledOption, StyledSelect } from './style';

type SelectProps = {
  label?: string;
  placeHolder: string;
  required?: boolean;
  className?: string;
  onChange?: (value: string) => any;
  children: React.ReactNode;
};

export const Option = ({ value, children }: { value: string; children: React.ReactNode }) => {
  return <StyledOption value={value}>{children}</StyledOption>;
};

export const Select = ({ children, label, placeHolder, required = true, className, onChange }: SelectProps) => {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState('default');

  const hasValue = Boolean(value && value !== 'default');
  return (
    <StyledForm focus={focus} className={className}>
      <StyledSelect
        required={required}
        focus={focus}
        hasValue={hasValue}
        name={label.toLowerCase().replace(' ', '-')}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
        value={value}
        onChange={(e) => {
          if (onChange) onChange(e.target.value);
          setValue(e.target.value);
        }}
      >
        <StyledOption value="default" disabled hidden>
          {placeHolder}
        </StyledOption>
        {children}
      </StyledSelect>
      <StyledLabel hasValue={hasValue} focus={focus}>
        {label}
      </StyledLabel>
    </StyledForm>
  );
};
