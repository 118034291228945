import React from 'react';
import { BountyType } from 'src/cms/interfaces/bounty';
import { ContentSection } from 'src/components/layout/layout';
import 'twin.macro';
import { BackLink } from '../lib/navlinks/back';
import { Tag } from '../lib/tag';
import { Form } from './form';
import { Author, SubmitTitle } from './style';

export const SubmitReport = ({ bounty }: { bounty: BountyType }) => {
  return (
    <ContentSection>
      <BackLink />
      <SubmitTitle>Submit Report to {bounty.title}</SubmitTitle>
      <Author>Author: {bounty.author}</Author>
      <div className="flex my-4">
        <Tag className="mr-4">Total Bounty Up To: 5.3 BTC</Tag>
        <Tag>5.1 - .53 BTC p/ vulnerability</Tag>
      </div>{' '}
      <Form bountyId={bounty.id} />
    </ContentSection>
  );
};
