import styled from 'styled-components';

export const SubmitTitle = styled.div`
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 52px;
`;

export const Author = styled.div`
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
`;
