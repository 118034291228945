import React, { useState } from 'react';
import { useGetAllRewards } from 'src/cms';
import { PrimaryButton } from 'src/components/lib/button';
import { Option, Select } from 'src/components/lib/select';
import 'twin.macro';
import { StyledForm, StyledTextArea, StyledTextField } from './style';

export function Form({ bountyId }: { bountyId: string }) {
  const rewards = useGetAllRewards();
  const filesState = useState<string>();

  return (
    <StyledForm
      name="submit-report"
      method="POST"
      data-netlify="true"
      action="/thank-you?type=submit-report"
      encType="multipart/form-data"
    >
      <input type="hidden" name="form-name" value="submit-report" />
      <div className="grid grid-cols-1 lg:grid-cols-12">
        <div className="col-span-8">
          <StyledTextField required label="Report Title" />
        </div>
        <div className="col-span-4">
          <StyledTextField required label="Email" />
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-12">
        <div className="col-span-8">
          <StyledTextField required label="BIP Number" />
        </div>
        <div className="col-span-4">
          <div className="m-3 lg:m-4">
            <Select placeHolder="Select Category" required label="VRT Category">
              {rewards.map((reward, index) => (
                <Option key={index} value={reward.name}>
                  {reward.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-12">
        <div className="col-span-4">
          <StyledTextField required label="First Name" />
        </div>
        <div className="col-span-4">
          <StyledTextField required label="Last Name" />
        </div>
      </div>
      <StyledTextArea filesState={filesState} required chars={25000} label="Message" placeHolder="Your Message" />

      <div className="flex justify-end m-5">
        <PrimaryButton className="w-56" type="submit">
          Submit Report
        </PrimaryButton>
      </div>
    </StyledForm>
  );
}
