import styled from 'styled-components';

type FormProps = {
  focus: boolean;
  hasValue?: boolean;
};

export const StyledForm = styled.div<FormProps>`
  position: relative;
  height: 68px;
  cursor: pointer;
`;

export const StyledLabel = styled.label<FormProps>`
  ${({ focus, hasValue }) =>
    focus || hasValue
      ? `
          text-transform: uppercase;
          position: absolute;
          top: 10px;
          left: 18px;
          display: block;
          transition: 0.2s;
          font-size: 12px;
          color: orange;
        `
      : `
        display: none;
      `}
`;

const selectArrowStyles = `
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
`;

export const StyledSelect = styled.select<FormProps>`
  font-family: inherit;
  height: 68px;
  width: 100%;
  border: 1px solid #d2d2d2;
  outline: 0;
  font-size: 16px;
  background: transparent;
  transition: border-color 0.2s;
  border-radius: 6px;
  padding: 7px 18px;

  ${({ focus, hasValue }) => (focus || hasValue) && 'padding: 22px 18px 0px 18px;'}
  ${({ focus }) => focus && 'border: 2px solid #212121;'}
  ${({ focus, hasValue }) => !focus && !hasValue && 'color: #919191;'}

  ${selectArrowStyles}
`;

export const StyledOption = styled.option``;
